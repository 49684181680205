import React, { useState, useEffect } from 'react';
import { QRCodeSVG } from 'qrcode.react';
import './QRCode.css';

const QRCodeComponent = () => {
  // State for loading and error handling
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  // Backend API endpoint - replace with your actual API URL
  const API_URL = process.env.REACT_APP_API || 'https://nimblecasa.com';
  
  const storeLinks = {
    ios: 'https://apps.apple.com/ng/app/nimblecasa-apartments/id6740244804',
    android: 'https://play.google.com/store/apps/details?id=com.google.android.googlequicksearchbox',
  };

  // Function to handle QR code scan
  const handleDownload = async () => {
    setIsLoading(true);
    setError(null);

    try {
      const response = await fetch(`${process.env.REACT_APP_API}/qrcode/download`, {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'User-Agent': navigator.userAgent // Backend will use this for detection
        }
      });

      if (!response.ok) {
        error = new Error('Internal Server Error (500)');
      }

      const data = await response.json();
      
      // Redirect to appropriate store/website
      window.location.href = data.redirectUrl;
      
    } catch (err) {
      setError(err.message);
      // Fallback to default website if there's an error
      window.location.href = 'https://www.nimblecasa.com';
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="qr-card">
      {/* <div className="qr-card-header">
        <h1 className="qr-card-title">Download Our App</h1>
      </div> */}
      
      <div className="qr-card-content">
        {/* QR Code Section */}
        <div className="qr-code-container">
          <QRCodeSVG 
            // Encode the API endpoint directly in the QR code
            value={`${API_URL}/qrcode/download`}
            size={200}
            level="H"
            includeMargin={true}
          />
        </div>
        
        {/* Error Message */}
        {error && (
          <p className="error-message">
            {error}
          </p>
        )}
        
        {/* Instructions */}
        <p className="qr-instructions">
          Scan the QR code to download our app
        </p>
        
        {/* Store Badges */}
        <div className="store-badges">
          {/* App Store Badge */}
          <a 
            href={storeLinks.ios}
            target="_blank"
            rel="noopener noreferrer"
            className="store-link"
            onClick={(e) => {
              e.preventDefault();
              handleDownload();
            }}
          >
            <img
              // src="/api/placeholder/120/40"
               src="./AppStore.png"
              alt="Download on App Store"
              className="store-badge"
            />
          </a>
          
          {/* Google Play Badge */}
          <a 
            href={storeLinks.android}
            target="_blank"
            rel="noopener noreferrer"
            className="store-link"
            onClick={(e) => {
              e.preventDefault();
              handleDownload();
            }}
          >
            <img
              // src="/api/placeholder/135/40"
              src="./GooglePlay.png"
              alt="Get it on Google Play"
              className="store-badge"
            />
          </a>
        </div>
      </div>
    </div>
  );
};

export default QRCodeComponent;