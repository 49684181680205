import React, { useState, useEffect } from "react";
import "./OfferSection..css";

const OfferSection = () => {
  const [currentSlide, setCurrentSlide] = useState(0);

  const slides = [
    "/shortletslideshow3.webp",
    "/shortletsslideshow1.webp",
    "/shortletsslideshow2.webp",
    "/shortletslideshow4.webp",
    // "https://nimbleletprodstorage.blob.core.windows.net/web-images/shortlet slideshow 3.jpg",
    // "/https://nimbleletprodstorage.blob.core.windows.net/web-images/shortlet slideshow 4.jpg",
    // "/https://nimbleletprodstorage.blob.core.windows.net/web-images/shortlets slideshow 1.jpg",
    // "/https://nimbleletprodstorage.blob.core.windows.net/web-images/shortlets slideshow 2.jpg",
  ];

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentSlide((prev) => (prev === slides.length - 1 ? 0 : prev + 1));
    }, 3000);
    return () => clearInterval(timer);
  }, [slides.length]);

  return (
    <div className="rental-container">
      {/* slide Section */}
      <div className="slide-section">
        <div className="slideshow-container">
          {slides.map((slide, index) => (
            <div
              key={index}
              className={`slide ${currentSlide === index ? "active" : ""}`}
            >
              <img src={slide} alt={`Slide ${index + 1}`} />
            </div>
          ))}
        </div>

        <div className="slide-content">
          <h1>Find Your Perfect Stay, In Minutes!!</h1>
          <p>
            Explore a variety of shortlet rentals to match your style and
            budget.
          </p>
        </div>
      </div>

      {/* What we Offer Section */}
      <section className="offer-section">
        <h2>What we Offer</h2>

        <div className="offer-grid">
          <div className="offer-card">
            <div className="offer-icon">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="26"
                height="26"
                fill="currentColor"
                class="bi bi-geo-alt-fill"
                viewBox="0 0 16 16"
                color="#EE7B0D"
              >
                <path d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10m0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6" />
              </svg>
            </div>
            <div className="offer-content">
              <h3>Location</h3>
              <p>Easy access to prime locations in Lagos to fit your style.</p>
            </div>
          </div>

          <div className="offer-card">
            <div className="offer-icon">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="26"
                height="26"
                fill="currentColor"
                class="bi bi-basket2-fill"
                viewBox="0 0 16 16"
                color="#EE7B0D"
              >
                <path d="M5.929 1.757a.5.5 0 1 0-.858-.514L2.217 6H.5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h.623l1.844 6.456A.75.75 0 0 0 3.69 15h8.622a.75.75 0 0 0 .722-.544L14.877 8h.623a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1.717L10.93 1.243a.5.5 0 1 0-.858.514L12.617 6H3.383zM4 10a1 1 0 0 1 2 0v2a1 1 0 1 1-2 0zm3 0a1 1 0 0 1 2 0v2a1 1 0 1 1-2 0zm4-1a1 1 0 0 1 1 1v2a1 1 0 1 1-2 0v-2a1 1 0 0 1 1-1" />
              </svg>
            </div>
            <div className="offer-content">
              <h3>Amenities</h3>
              <p>
                State of the art amenities in every facility for a comfortable
                stay.
              </p>
            </div>
          </div>

          <div className="offer-card">
            <div className="offer-icon">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="26"
                height="26"
                fill="currentColor"
                class="bi bi-credit-card-fill"
                viewBox="0 0 16 16"
                color="#EE7B0D"
              >
                <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v1H0zm0 3v5a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V7zm3 2h1a1 1 0 0 1 1 1v1a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1v-1a1 1 0 0 1 1-1" />
              </svg>
            </div>
            <div className="offer-content">
              <h3>Payment</h3>
              <p>Experience peace of mind with our secure payment options.</p>
            </div>
          </div>

          <div className="offer-card">
            <div className="offer-icon">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="26"
                height="26"
                fill="currentColor"
                class="bi bi-person-hearts"
                viewBox="0 0 16 16"
                color="#EE7B0D"
              >
                <path
                  fill-rule="evenodd"
                  d="M11.5 1.246c.832-.855 2.913.642 0 2.566-2.913-1.924-.832-3.421 0-2.566M9 5a3 3 0 1 1-6 0 3 3 0 0 1 6 0m-9 8c0 1 1 1 1 1h10s1 0 1-1-1-4-6-4-6 3-6 4m13.5-8.09c1.387-1.425 4.855 1.07 0 4.277-4.854-3.207-1.387-5.702 0-4.276ZM15 2.165c.555-.57 1.942.428 0 1.711-1.942-1.283-.555-2.281 0-1.71Z"
                />
              </svg>
            </div>
            <div className="offer-content">
              <h3>Guest Satisfaction</h3>
              <p>
                We prioritize maintaining a 5 star experience for every guest.
              </p>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default OfferSection;
