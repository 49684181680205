import React from "react";
import "./DownloadButtons.css";
import Tooltip from "@mui/material/Tooltip";
import QRCodeComponent from "../qrcode/QRCode";

const DownloadButtons = () => {
  return (
    <div className="download-main-container">
      <div className="download-container">
        <div className="content-wrapper">
          <h1>Download Now</h1>
          <div className="button-container">
            <Tooltip title="Get it on Google Play">
              <a style={{pointerEvents: "none"}}
                // href="https://play.google.com/store/apps/details?id=com.nimblecasa.app"
                href="/"
                target="_blank"
                className="store-button"
              >
                <img
                  className="app-download-image"
                  src="./GooglePlay.png"
                  alt="Get it on Google Play"
                  aria-label="Get it on Google Play"
                />
              </a>
            </Tooltip>
            <Tooltip title="Download on the App Store">
              <a style={{pointerEvents: "none"}}
                // href="https://apps.apple.com/ng/app/nimblecasa-real-estate-app/id6477383845"
                href="/"
                target="_blank"
                className="store-button"
              >
                <img
                  className="app-download-image"
                  src="./AppStore.png"
                  alt="Download on the App Store"
                  aria-label="Download on the App Store"
                />
              </a>
            </Tooltip>
          </div>
          <QRCodeComponent />
        </div>
      </div>
    </div>
  );
};

export default DownloadButtons;
