import React, { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import "./index.css";

const TermsofUse = () => {
  const navigate = useNavigate();
  const { state } = useLocation();

  useEffect(() => {
    // Scroll to the top of the page when the component mounts
    window.scrollTo(0, 0);
  }, []);

  const handleBackButton = () => {
    navigate("/");
  };

  return (
    <div className="container-fluid my-5 p-3">
      <div className="h1 text-center m-header">
        <div className="h1 text-center p-header">
          TERMS & CONDITIONS FOR PROPERTY SHORT LET RENTALS-HOSTS
        </div>
        <ol>
          <li>
            <p className="list-heading">SCOPE</p>
            <p style={{ textAlign: "left" }}>
              This contract outlines the terms upon which our company will
              provide its marketing platform to showcase your short-let
              facilities to guests. This document, therefore, serves as a
              binding contract between yourself and our company, and all terms
              hereunder will govern the relationship created herein for the
              duration of our service engagement and beyond, as applicable.
            </p>
          </li>

          <li>
            <p className="list-heading">OUR RELATIONSHIP</p>
            <p style={{ textAlign: "left" }}>
              This agreement does not create a Landlord and tenant, employee
              employer, partnership, joint venture relationship. Parties are
              independent contractors and have the liberty to contract freely
              outside the terms of this agreement, provided always that there
              will be no conflict with the terms set out herein.
            </p>
          </li>

          <li>
            <p className="list-heading">YOUR APARTMENT(S)</p>
            <p style={{ textAlign: "left" }}>
              We provide a marketing platform for short let apartments globally.
              Our platform provides the opportunity for property owners to earn
              income for the use of property that would otherwise have been
              dormant or under-utilised. We therefore provide a unified platform
              within which any available property of your choosing may be signed
              up for marketing and operations in a safe, transparent and
              flexible manner. The pictorial or video representation and
              physical ambiance of the property as represented and presented to
              prospective customers will be your sole responsibility at all
              times. We therefore advise the highest standards of practise as
              you will be wholly and entirely liable for any claims for
              misrepresentation, negligence, recklessness or any other damages
              incurred by customers.  
            </p>
          </li>

          <li>
            <p className="list-heading">YOUR ELIGIBILITY</p>
            <p style={{ textAlign: "left" }}>
              <strong>Age</strong>
              <br />
              You must be at least 18 years old to sign up for an apartment.
              Proof of age with a valid ID will be required during
              administrative processes. Any falsification of personal
              information will result in immediate cancellation of the terms of
              this agreement.
              <br />
              <br />
              <strong>Apartment ownership/authority</strong>
              <br />
              You must own the apartment or have the express authority to sign
              up the apartment from the owner accordingly. We will not be held
              liable for any unauthorised use of property or provision of
              misleading facts to the ownership or authority accordingly. Legal
              documentation for proof of ownership or renter agreement will be
              required during administrative signup. Falsification of documents
              will be grounds for the immediate termination of the terms herein,
              and this shall be without prejudice to the company's rights
              against you for any liability incurred. 
            </p>
          </li>

          <li>
            <p className="list-heading">YOUR PROFILE</p>
            <p style={{ textAlign: "left" }}>
              Successful signup will amount to you being assigned a profile.
              Your profile is your personal platform wherein your apartment(s)
              are uploaded and managed by yourself. Your personal data will be
              required for profile creation within which process you may be
              required to upload proof of personal documentation in proof of
              your identity.
            </p>
          </li>

          <li>
            <p className="list-heading">PROFILE ACTIVITIES</p>
            <p style={{ textAlign: "left" }}>
              Our cutting-edge platform will issue your apartment/personal
              profiles within which you can monitor and control apartment
              activities.
              <br />
              <br />
              <strong>Booking and scheduling</strong>
              <br />
              You will be responsible for apartment scheduling, and the company
              will not be held responsible for schedule clashes under any
              circumstances.
              <br />
              <br />
              <strong>Visual Uploads</strong>
              <br />
              You will be in control of the uploads and posting of your
              apartment on the platform which will be displayed for public
              viewing from the point of uploads. You are therefore required to
              ensure the highest quality visual representations are uploaded for
              the best apartment marketability.
              <br />
              <br />
              <strong>Apartment description</strong>
              <br />
              You will also be required to describe in written detail to the
              best of your ability the special and unique features or additional
              facilities and services available in the apartment, as well as any
              special notes which you believe will make the apartment more
              marketable.
              <br />
              <br />
              <strong>Special policies</strong>
              <br />
              You will be at liberty to choose any special policies applicable
              to your apartment such as but not limited to smoking, pets,
              parties and social gatherings, limitation to guest numbers, etc.
              This will assist in the general public understanding of what is
              permitted or otherwise while your apartment is in use. The company
              shall be at liberty to use in-app special features such as
              drop-down options or any other record alternatives to represent
              your choice of policies in your upload process accordingly.
              <br />
              <br />
              <strong>Communication with guests</strong>
              <br />
              The platform will provide chat windows within which guests may
              interface with you directly. You shall ensure respectful and
              lawful communication with guests in the event of any interactions
              accordingly. The company reserves the right to monitor guest
              interfaces on the platforms at all times to ensure quality
              control.
              <br />
              <br />
              <strong>Profile Security</strong>
              <br />
              The company shall also take steps to provide security mechanisms
              on its platform such as usernames and passwords which will be your
              responsibility to keep safe.
            </p>
          </li>

          <li>
            <p className="list-heading">CANCELLATIONS AND RESCHEDULING</p>
            <p style={{ textAlign: "left" }}>
              We acknowledge the existence of special circumstances that may
              require booking cancellations on the part of either the guests or
              the hosts. Cancellations may however cause the destabilization of
              schedules and the disruption of business in general. We therefore
              strongly discourage indiscriminate schedule cancellation by either
              party once bookings are locked in.
              <br />
              <br />
              <strong>Host cancellations</strong>
              <br />
              In the event that cancellations are unavoidable for the Hosts, the
              host must provide a minimum of 72 hours' notice to the guests via
              the platform and any other authorised modes of interface. This
              will enable the guest(s) to source for alternative accommodation
              within a reasonable time. The company will initiate full refunds
              to the guest automatically upon the appropriate prompting on or
              off the platform. Applicable administrative fees for the use of
              the platform shall not be refundable to the host.
              <br />
              <br />
              In the event of an emergency booking by guests under 48 hours
              before the time of guest arrival, the host must give cancellation
              notice within 24 hours of guest arrival. Host cancellations are
              however highly discouraged and the company reserves the right to
              suspend or delete your profile based on continued receipt of
              complaints and report of schedule cancellation.
              <br />
              <br />
              The company shall not be responsible for any damages caused by the
              cancellation by guests. You shall therefore indemnify the company
              against any 3rd party claims accordingly.
              <br />
              <br />
              <strong>Guest cancellations</strong>
              <br />
              The company shall provide cancellation policy options on the
              platform to choose from. You are encouraged to select favourable
              cancellation policies for better apartment marketability.
              Cancellation timelines selected will be displayed on the
              applicable apartment profile for public viewing. Cancellation by
              guests will amount to a total or partial refund of funds advanced
              by guests less applicable cancellation fees in accordance with the
              cancellation policy chosen. The company default guest cancellation
              policy is as follows:
              <br />
              <br />
              <strong>Policy 1</strong>
              <br />
              Cancellations made over 48 hours before check-in will be free of
              charge- a full refund of the deposit will be made. Cancellation
              made in less than 48 hours of check-in- 5% deposit amount will be
              charged penalty. No refunds are applicable if you do not give at
              least 24 hours notice of no-show at check-in.
              <br />
              <br />
              <strong>Policy 2</strong>
              <br />
              Cancellations made over to within 24 hours will attract a 50%
              refund. Cancellations made in less than 12 hours of check-in will
              attract no refund.
              <br />
              <br />
              <strong>Policy 3</strong>
              <br />
              Cancellation anytime equals no refund.
              <br />
              <br />
              You may adopt the company cancellation policy as related to each
              individual booking, or vary same based on your preference on your
              profile. Same will be binding on guests once accepted.
              <br />
              {/* Cancellation fees/penalties applicable will be paid to the
              host-designated account less administrative and statutory charges. */}
            </p>
          </li>

          <li>
            <p className="list-heading">APARTMENT MAINTENANCE</p>
            <p style={{ textAlign: "left" }}>
              You shall be responsible for the maintenance of the apartments
              during and after guest's usage. You are also required to remove
              any items of value or sentiments from the apartments before guest
              arrival as the company will not be held liable for damage or loss
              to such items accordingly.
            </p>
          </li>

          <li>
            <p className="list-heading">RATES AND PROCEEDS</p>
            <p style={{ textAlign: "left" }}>
              Apartment rates are posted against each apartment at your
              discretion using your profile. The company however advises price
              parity in your pricing to ensure adequate marketability of your
              apartment.
            </p>
          </li>

          <li>
            <p className="list-heading">PAYMENT AND REMITTANCE</p>
            <p style={{ textAlign: "left" }}>
              Payment of rental fees by guests shall be done through the
              platform and funds will be disbursed to your account when
              apartment availability has been confirmed. The company will take
              no responsibility for any transactions and payments handled
              outside its platform between host and guests.
            </p>
          </li>

          <li>
            <p className="list-heading">COMPANY ADMINISTRATIVE CHARGES</p>
            <p style={{ textAlign: "left" }}>
              <strong>Commission on rental:</strong> To help our platform run
              smoothly, a service fee is charged to the guest account for every
              successful booking.
              <br />
              <br />
              <strong>Taxes and other statutory fees:</strong> You are
              responsible for remitting taxes and other statutory fees on each
              transaction consummated on the platform to relevant authorities.
            </p>
          </li>

          <li>
            <p className="list-heading">RANKS AND RATINGS</p>
            <p style={{ textAlign: "left" }}>
              The company reserves the right to operate a rating system for
              hosts. The said rating system shall depend on algorithms and data
              collated from the platform such as but not limited to apartment
              quality, guest experiences in general during their visit, guest
              interaction and interface, seamless scheduling, guest commentary
              and star ratings.
              <br />
              <br />
              Ratings are a general representation of your performance on our
              platform as a short let service provider. Your general ratings
              also provide for platform security and transparency and may have
              an impact on the marketability of apartments on your profile.
              <br />
              <br />
              You consent to the publication of guests' comments for public
              viewing on the company platform accordingly.
            </p>
          </li>

          <li>
            <p className="list-heading">GENERAL TERMS OF USE</p>
            <p style={{ textAlign: "left" }}>
              By virtue of your acceptance of these terms, you are bound by the
              <Link
                style={{ color: "#ee7b0d", textDecoration: "none" }}
                to="/terms-of-use-general"
              >
                <i> general terms of use</i>
              </Link>{" "}
              of our platform in its entirety.
            </p>
          </li>

          <li>
            <p className="list-heading">
              PEACEFUL OCCUPATION OF APARTMENT BY GUESTS
            </p>
            <p style={{ textAlign: "left" }}>
              It is of utmost importance that guests enjoy a peaceful occupation
              of apartments during their respective time slots. Therefore, any
              complaints of disturbance by guests during their stay may lead to
              penalties such as profile suspension, or termination accordingly.
              You will also be solely liable for any claims by guests for
              nuisance, damage and or injury to property or person as a result
              of the disturbance of peaceful occupation based on the host's
              actions, or inactions.
            </p>
          </li>

          <li>
            <p className="list-heading">LIABILITY</p>
            <p style={{ textAlign: "left" }}>
              We are not responsible in any way for loss of or damage to you or
              to your personal belongings. You are advised to keep your
              belongings safe or remove same from the apartments before Sign-up
              or opening up. Nothing in this contract seeks to exclude liability
              not excusable under the laws of Nigeria.
            </p>
          </li>

          <li>
            <p className="list-heading">INSURANCE</p>
            <p style={{ textAlign: "left" }}>
              We advise hosts to acquire adequate insurance coverage against
              risks and damage to apartments such as but not limited to fire,
              other relevant perceived forms of damage, burglary, theft, etc.
              The company will not be liable in any way for damage to apartments
              accordingly.
            </p>
          </li>

          <li>
            <p className="list-heading">GOVERNING LAWS</p>
            <p style={{ textAlign: "left" }}>
              This Agreement shall be governed by the laws of the Federal
              Republic of Nigeria.
            </p>
          </li>

          <li>
            <p className="list-heading">DISPUTE RESOLUTION</p>
            <p style={{ textAlign: "left" }}>
              <strong>Dispute escalation procedure for Hosts and guests</strong>
              <ol start="1">
                <li>
                  In the event of disputes raised by hosts or guests, such
                  disputes may be escalated to the company management who shall
                  take steps to resolve issues amicably.
                </li>
                <li>
                  Where no resolution is reached, parties will be advised to
                  approach the Lagos State Multi-Door Courthouse for mediation
                  or arbitration or to any court of competent jurisdiction.
                </li>
                <li>
                  Nothing in this Agreement shall preclude parties from
                  approaching any court of competent jurisdiction for any
                  judgment or order for injunction, damages, specific
                  performance etc.
                </li>
              </ol>
              <br />
              <strong>Dispute resolution between Hosts and company</strong>
              <br />
              Parties shall endeavour to resolve all disputes amicably by
              negotiation. Where no resolution is reached, parties shall refer
              the dispute to the Lagos State Multi-Door Courthouse for mediation
              or arbitration or to any court of competent jurisdiction.
            </p>
          </li>

          <li>
            <p className="list-heading">ACKNOWLEDGMENT AND ACCEPTANCE</p>
            <p style={{ textAlign: "left" }}>
              I understand and agree to the terms stated herein and by signing
              this agreement, I agree to all terms and conditions.
            </p>
          </li>
        </ol>

        <div className="d-flex justify-content-around">
          <button
            className="btn btn-primary col-4 md-col-8 my-4"
            onClick={handleBackButton}
          >
            Back
          </button>
        </div>
      </div>
    </div>
  );
};

export default TermsofUse;
