import React, { useState } from 'react';
import './FeaturesSection.css';

const FeaturesList = () => {
  const [activeTab, setActiveTab] = useState('guest');

  const guestFeatures = [
    {
      title: "Instant or Request Booking",
      description: "Choose how you want to secure your stay - instantly or with host approval."
    },
    {
      title: "Secure Payments",
      description: "Safe and straightforward transactions with a variety of payment methods."
    },
    {
      title: "Tailored Search",
      description: "Filter your search by location, amenities, or price for the perfect match."
    },
    {
      title: "Real-Time Notifications",
      description: "Stay updated with every step of your booking journey."
    }
  ];

  const hostFeatures = [
    {
      title: "List with Ease",
      description: "Turn your property into revenue with simple listing process."
    },
    {
      title: "Control Your Space",
      description: "Manage bookings, set availability, and adjust pricing on-the-go."
    },
    {
      title: "Secure Verification",
      description: "Safeguard your listing with our thorough verification process."
    },
    {
      title: "Settlement",
      description: "Receive 100% of your set accommodation fee."
    }
  ];

  return (
    <div className="features-container">
      <h1 className="features-title">Features</h1>
      
      <div className="tab-buttons">
        <button
          onClick={() => setActiveTab('guest')}
          className={`tab-button ${activeTab === 'guest' ? 'active' : ''}`}
        >
          Guest
        </button>
        <button
          onClick={() => setActiveTab('host')}
          className={`tab-button ${activeTab === 'host' ? 'active' : ''}`}
        >
          Host
        </button>
      </div>

      <div className="content-container">
        <div className="content-sections">
          {activeTab === 'guest' ? (
            <div className="features-list">
              {guestFeatures.map((feature, index) => (
                <div key={index} className="feature-card">
                  <h3 className="feature-title">{feature.title}</h3>
                  <div className="feature-description">{feature.description}</div>
                </div>
              ))}
            </div>
          ) : (
            <div className="image-container">
              <img 
                src="/forHosts.webp" 
                // src="https://nimbleletprodstorage.blob.core.windows.net/web-images/For Hosts.jpg" 
                alt="Guest features placeholder" 
                className="feature-image"
              />
            </div>
          )}
        </div>

        <div className="content-sections">
          {activeTab === 'host' ? (
            <div className="features-list">
              {hostFeatures.map((feature, index) => (
                <div key={index} className="feature-card">
                  <h3 className="feature-title">{feature.title}</h3>
                  <div className="feature-description">{feature.description}</div>
                </div>
              ))}
            </div>
          ) : (
            <div className="image-container">
              <img 
                src="/forguests.webp" 
                // src="https://nimbleletprodstorage.blob.core.windows.net/web-images/for guests.jpg" 
                alt="Host features placeholder" 
                className="feature-image"
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default FeaturesList;