import React, { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import "./index.css";

const TermsofUse = () => {
  const navigate = useNavigate();
  const { state } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleBackButton = () => {
      navigate("/");
  };

  return (
    <div className="container-fluid my-5 p-3">
      <div className="h1 text-center m-header">
        <div className="h1 text-center p-header">
          TERMS AND CONDITIONS FOR USE OF PLATFORMS
        </div>
        <ol>
          <li>
            <p className="list-heading">BINDING NATURE</p>
            <p style={{ textAlign: "left" }}>
              By accessing our services, you are deemed to have accepted to be
              bound by these terms and conditions. If you disagree with these
              general terms and conditions or any part of these general terms
              and conditions, you must not use our services. Our terms and
              policies contain a number of legal obligations and additional
              terms or product requirements which you are presumed to be
              familiar with. We encourage you to thoroughly read through.
              Additional terms may be added from time to time. Those additional
              terms become part of your agreement with us and will be equally
              binding on you at all times.
            </p>
          </li>

          <li>
            <p className="list-heading">USER REGISTRATION</p>
            <p style={{ textAlign: "left" }}>
              You may be required to register for this Service. You agree to
              keep your password confidential and will be responsible for all
              use of your account and password. We reserve the right to remove,
              reclaim, or change a username you select if we determine, in our
              sole discretion, that such username is inappropriate, obscene, or
              otherwise objectionable.
            </p>
          </li>

          <li>
            <p className="list-heading">ACCESS TO SERVICE</p>
            <p style={{ textAlign: "left" }}>
              a. Your access or continued access to our services is dependent on
              your Compliance with all the terms and conditions of this
              Agreement and any other future modifications of or additions to
              this Agreement or directives as may be prescribed from time to
              time.
              <br />
              <br />
              b. We reserve the right to impose registration or subscription
              fees at any time. Where such fees are applied, you will be
              required to comply with payment of same before being granted
              access to our services. Failure to comply shall be a ground for
              access denial, or suspension of our services forthwith.
            </p>
          </li>

          <li>
            <p className="list-heading">USER DATA AND INFORMATION</p>
            <p style={{ textAlign: "left" }}>
              We will maintain certain data that you transmit to the Service for
              the purpose of managing the Service, as well as data relating to
              your use of the Service. Although we perform regular routine
              backups of data, you are solely responsible for all data that you
              transmit or that relates to any activity you have undertaken using
              the Service. You agree that we shall have no liability to you for
              any loss or corruption of any such data, and you hereby waive any
              right of action against us arising from any such loss or
              corruption of such data.
            </p>
          </li>

          <li>
            <p className="list-heading">THIRD-PARTY CONTENT AND SITES</p>
            <p style={{ textAlign: "left" }}>
              Our service offer access to third-party applications. You
              acknowledge that we exercise absolutely no control over such
              third-party content, or sites and in such cases, our application
              is merely a conduit or means of access and transmission.
            </p>
          </li>

          <li>
            <p className="list-heading">USER RESPONSIBILITIES</p>
            <p style={{ textAlign: "left" }}>
              a. You will provide us with such information as we may reasonably
              require enabling us to perform our obligations or exercise our
              rights under these Agreement.
              <br />
              <br />
              b. You will provide us with accurate and up-to-date information.
              <br />
              <br />
              c. You are responsible for any misuse of our services that occurs
              through your account. It is your responsibility to ensure that
              unauthorized persons do not gain access to or misuse our service.
              <br />
              <br />
              d. We urge you not to reply to unsolicited mail or "spam" and not
              to click on any suggested links provided in the unsolicited mail.
              Doing so remains solely your responsibility and we cannot be held
              liable for the Customer being placed on any bulk mailing lists as
              a result.
              <br />
              <br />
              e. Where you have authorised a minor to use any of our services or
              access websites, you accept that as the parent/legal guardian of
              that minor, you are fully responsible for: the online conduct of
              such minor; controlling the minor's access to and use of any
              services or websites; and the consequences of any misuse by the
              minor, including but not limited to transactions entered into by
              the minor using such access.
              <br />
              <br />
              f. You will comply with all relevant laws, legislation,
              regulations and rules relating to use of the Access Device and the
              Services and for internet-based communications and businesses.
              <br />
              <br />
              g. You undertake not to use the Services for any illegal or
              immoral purposes and you will abide by the current version of the
              Acceptable Use Policy, available as acceptable-use-policy.
              <br />
              <br />
              h. You shall not act, whether by commission or omission, in any
              way likely to injure or damage any person, property or the
              application or cause the quality of the application to be impaired
              or interrupted in any manner and you fully indemnify us against
              any liability, damage or loss arising from any unlawful, illegal
              or improper use of the application.
              <br />
              <br />
              i. You shall not access, transmit, store or distribute any data,
              material or content in violation of any applicable law or
              regulation or any acceptable use policy of any application or
              system or any of our third party providers or which infringes on
              our intellectual property rights or any third party or violates
              the privacy of others or which materially affects the quality of
              Services or other telecommunications services provided from time
              to time.
              <br />
              <br />
              j. You shall not utter, publish, access, transmit, store,
              distribute or create any offensive, obscene, harmful, indecent,
              illegal, discriminatory, inflammatory or unlawful images or
              content, data or other material, or any data capable of resulting
              in offensive, obscene, harmful, indecent, illegal, discriminatory,
              inflammatory or unlawful images or material.
              <br />
              <br />
              k. You shall not scan the platform for vulnerabilities without
              authorization.
              <br />
              <br />
              l. You shall not simulate communications from and/or to the
              website or other service of another entity in order to collect
              identity information, authentication credentials, or other
              information from the legitimate users of that entity's service
              (phishing).
              <br />
              <br />
              m. You shall not execute any form of application monitoring (e.g.
              using a packet sniffer) or otherwise engage in any monitoring or
              interception of data not intended for you without authorization.
              <br />
              <br />
              n. You shall not Except as provided for in this Agreement cede any
              of its rights or delegate any of its obligations under this
              Agreement without our prior written consent.
            </p>
          </li>

          <li>
            <p className="list-heading">PURCHASES</p>
            <p style={{ textAlign: "left" }}>
              All payments are redirected to 3rd party payment platforms or
              websites for processing of payment of our products and services,
              which are not operated by us. We do not store any payment
              information nor do we operate these platforms or websites and are
              not responsible for their data or privacy practices. We urge you
              to review any privacy policy posted on any site you visit before
              using the site or providing any personal information about
              yourself and others.
            </p>
          </li>

          <li>
            <p className="list-heading">
              PROVISION OF INFORMATION TO CUSTOMERS
            </p>
            <p style={{ textAlign: "left" }}>
              a. You will be provided with completely accurate and up-to-date
              information about our products and services in simple and
              unambiguous languages.
              <br />
              <br />
              b. Before entering into terms and conditions for any service, you
              shall be provided with a complete description of the service in
              clear and plain language. Where other services are required in
              order to effectively utilize the service, you shall be
              sufficiently informed of such requirements or service
              dependencies.
            </p>
          </li>

          <li>
            <p className="list-heading">
              TECHNICAL MEASURES TO PREVENT ABUSE OF THE ACCESS SERVICE
            </p>
            <p style={{ textAlign: "left" }}>
              We reserve the right to take such action as may be necessary to
              protect the integrity of our services, including, system
              monitoring, protocol management and shutting down of ports
              affected by viruses, worms or other malicious code.
            </p>
          </li>

          <li>
            <p className="list-heading">SERVICE SUSPENSION</p>
            <p style={{ textAlign: "left" }}>
              a. We may, at our sole discretion and without prejudice to any
              right which we might have to terminate a Service and/or this
              Agreement, elect to immediately suspend the provision of a Service
              (or part thereof) if:
              <br />
              <br />
              i. We have reasonable grounds to consider we are entitled to
              terminate the Service and/or this Agreement for any reasons.
              <br />
              <br />
              ii. We are obliged to comply with an order, instruction or request
              of a court, government agency, emergency service organization or
              other administrative or regulatory authority.
              <br />
              <br />
              iii. We need to carry out Emergency maintenance Works to the
              application
              <br />
              <br />
              iv. We have reasonable grounds to consider that the Service is
              being used fraudulently or illegally or in violation of any laws
              whatsoever
              <br />
              <br />
              b. We shall not be liable for any loss, damage or inconvenience
              suffered by you because of any suspension made except to the
              extent that such suspension is solely and directly attributable to
              our negligence.
            </p>
          </li>

          <li>
            <p className="list-heading">TERMINATION</p>
            <p style={{ textAlign: "left" }}>
              a. These General Terms shall take effect in respect of each
              Service, from the effective date of each service order.
              <br />
              <br />
              b. Where there are reasonable grounds to believe that there has
              been a violation of any of the terms herein, we may notify you and
              require you to remedy such violation of regulations and or an
              imminent threat to the application, or in all other cases, within
              forty-eight (48) hours.
              <br />
              <br />
              c. If We reasonably determine that the violation is continuing or
              is likely to reoccur, we may terminate this Agreement (or relevant
              Service) without further recourse to you.
              <br />
              <br />
              d. Notice of termination will be deemed sufficient were given in
              writing such as, hard copy letters, emails, text message and any
              other form of written communication which may come into existence
              in the future.
            </p>
          </li>

          <li>
            <p className="list-heading">DISCLAIMER</p>
            <p style={{ textAlign: "left" }}>
              Every effort will be made to provide the highest quality of
              service to the Customer. You acknowledge that our services are
              interconnected internet links provided by other service delivery
              providers/entities that are responsible for ensuring that these
              links are as reliable as possible. We do not own any
              responsibility in the event of interruptions beyond our reasonable
              control. We shall not be responsible for any interruption caused
              by the quality of these links, defect in connectivity or any
              inconvenience, damages, or any other liability whatsoever from
              Customers or anyone else in this regard.
            </p>
          </li>

          <li>
            <p className="list-heading">LIABILITY</p>
            <p style={{ textAlign: "left" }}>
              We shall not be held liable for any losses and /or any damages
              sustained due to any reason whatsoever OR for any indirect,
              incidental, special, consequential or punitive damages arising out
              of or in connection with this agreement.
            </p>
          </li>

          <li>
            <p className="list-heading">FORCE MAJEURE</p>
            <p style={{ textAlign: "left" }}>
              a. We are not liable for failure to perform its obligations if
              such failure include (but is not limited to acts of God, enemy
              hostilities, fire outbreak, flood, lightning strikes, earthquakes,
              storm, hurricane and or other natural disasters) war, invasion,
              act of foreign enemies(regardless of whether war is declared or
              not), civil war, rebellion, revolution, insurrection, military or
              usurped political powers or confiscation, terrorist activities,
              nationalization, government sanction, embargo, labor dispute,
              strike, lockout, or industrial action or failure of electricity or
              telecommunications services across the country, action of
              regulatory authorities, or local or national government or
              authorities or any event that can be reasonably termed a force
              majeure.
              <br />
              <br />
              b. Notwithstanding anything herein to the contrary, neither Party
              shall be liable to the other for any delay, failure in performance
              of any part of this Agreement (other than for payment obligations)
              or damages suffered to the extent that such delay or failure is
              attributable to a Force Majeure Event or any event that may be
              reasonably termed as a Force majeure.
            </p>
          </li>

          <li>
            <p className="list-heading">INTELLECTUAL PROPERTY</p>
            <p style={{ textAlign: "left" }}>
              You acknowledge that all patents, registered and unregistered
              designs, copyrights, trademarks and all other intellectual
              property rights whatsoever and wherever enforceable, which are
              used in connection with the Service, shall remain our sole
              property as well as relevant contractors or suppliers. You shall
              not in any way tamper with, modify or decompile or do such things
              as may affect the intellectual property rights of with respect to
              the provision and use of the Services.
            </p>
          </li>

          <li>
            <p className="list-heading">AMENDMENT OF TERMS AND CONDITIONS</p>
            <p style={{ textAlign: "left" }}>
              We reserve the right to change, modify or amend the terms and
              conditions of our services. Change may be made necessary due to:
              <br />
              <br />
              a. Amendment to any law or regulations governing our services
              <br />
              <br />
              b. At our sole discretion if we decide to amend terms for reasons
              of quality of service, or for the benefit of our customers or for
              business practices and policies changes.
              <br />
              <br />
              c. If there is any information on the Service that contains
              typographical errors, inaccuracies, or omissions that may relate
              to the Service, including descriptions, pricing, availability, and
              various other information. We reserve the right to correct any
              errors, inaccuracies, or omissions and to change or update the
              information on the Service at any time, without prior notice.
            </p>
          </li>

          <li>
            <p className="list-heading">INDEMNITY</p>
            <p style={{ textAlign: "left" }}>
              You will be responsible for and shall indemnify us and all of our
              respective officers, agents, partners, and employees, and hold us
              blameless against:
              <br />
              <br />
              a. Any direct or consequential liability arising out of claims
              made against us, our affiliates, employees, or representatives
              howsoever described in connection with the use of our service or
              misuse by you, or any other person including but not limited to
              claims of fraud, defamation, copyright infringement, or any other
              breach of intellectual property rights and any breach occasioned
              by non-observance of the terms and conditions of this agreement,
              <br />
              <br />
              b. Any breach of your representations and warranties set forth in
              these Terms of Use;
              <br />
              <br />
              c. Your violation of the rights of a third party, including but
              not limited to intellectual property rights; or any overt harmful
              act toward any other user of the Service with whom you connected
              via the Service.
              <br />
              <br />
              Notwithstanding the foregoing, we reserve the right, at your
              expense, to assume the exclusive defense and control of any matter
              for which you are required to indemnify us, and you agree to
              cooperate, at your expense, with our defense of such claims. We
              will use reasonable efforts to notify you of any such claim,
              action, or proceeding which is subject to this indemnification
              upon becoming aware of it.
            </p>
          </li>

          <li>
            <p className="list-heading">DISPUTE RESOLUTION:</p>
            <p style={{ textAlign: "left" }}>
              a. In the event of differences or disputes which may arise in
              connection with this agreement or its interpretation, the parties
              shall negotiate in good faith with a view to settling the matter
              amicably.
              <br />
              <br />
              b. Where negotiation fails, parties shall submit to the Lagos
              State Multidoor courthouse for Mediation or Arbitration. Provided
              that the parties shall bear their respective costs inclusive of
              attorney fees.
              <br />
            </p>
          </li>

          <li>
            <p className="list-heading">RESERVATION AND NON-WAIVER OF RIGHTS</p>
            <p style={{ textAlign: "left" }}>
              a. We reserve the right to amend or alter this policy at any time,
              and without notice to you.
              <br />
              <br />
              b. We reserve the right to take action against any individuals,
              companies or organizations that violate any of the prohibited
              activities set out herein or engage in any illegal or unlawful
              activity while accessing our services, to the fullest extent of
              the law.
              <br />
              <br />
              c. We reserve the right, at our sole discretion, to act against
              other types of abuse not listed in this document and to
              investigate or prevent illegal activities being committed under
              our application.
              <br />
              <br />
              d. We reserve the right to monitor user- and platform traffic for
              site security purposes and prevent any unauthorized attempts to
              tamper with our site or cause damage to our property.
              <br />
              <br />
              e. We reserve the right to suspend, revoke or cancel our services
              to you if the safety and integrity of our resources are placed at
              risk in continuing to provide service to the subscriber/user.
              <br />
              <br />
              f. We reserve the right to remove any information or materials in
              whole or in part, that, in our sole discretion, is deemed to be
              offensive, indecent, or otherwise objectionable.
              <br />
              <br />
              g. We do not undertake to guarantee the security of any data
              passing through the applications. Although we will provide a “best
              effort” service, including regular updates on computer viruses and
              other threats to security of data, it is the responsibility of the
              communicating parties to safeguard their data, and we cannot be
              held liable for any loss or damage arising as result of the
              failure to do so.
              <br />
            </p>
          </li>

          <li>
            <p className="list-heading">SURVIVAL</p>
            <p style={{ textAlign: "left" }}>
              Termination of this Agreement shall not affect a clause that
              necessarily or by its context requires survival of these General
              Terms. 
            </p>
          </li>

          <li>
            <p className="list-heading">SEVERABILITY</p>
            <p style={{ textAlign: "left" }}>
              If any clause or term of this Agreement should be invalid,
              unenforceable, defective or illegal for any reason whatsoever,
              then the remaining terms and provisions of this Agreement shall be
              deemed to be severable and shall continue in full force and effect
              unless such invalidity, unenforceability, defect or illegality
              goes to the root this Agreement. 
            </p>
          </li>

          <li>
            <p className="list-heading">MISCELLANEOUS</p>
            <p style={{ textAlign: "left" }}>
              a. These General Terms and establish the terms and conditions
              under which we shall provide the Services to you.
              <br />
              <br />
              b. These Terms of Use and any policies or operating rules posted
              by us on the Service constitute the entire agreement and
              understanding between you and us. Our failure to exercise or
              enforce any right or provision of these Terms of Use shall not
              operate as a waiver of such right or provision.
              <br />
              <br />
              c. These Terms of Use operate to the fullest extent permissible by
              law. We may assign any or all of our rights and obligations to
              others at any time.
              <br />
              <br />
              d. We shall not be responsible or liable for any loss, damage,
              delay, or failure to act caused by any cause beyond our reasonable
              control.
              <br />
              <br />
              e. There is no joint venture, partnership, employment or agency
              relationship created between you and us as a result of these Terms
              of Use or use of the Service.
              <br />
              <br />
              f. You agree that these Terms of Use will not be construed against
              us by virtue of having drafted them. You hereby waive any and all
              defenses you may have based on the electronic form of these Terms
              <br />
              <br />
              g. The rule of construction that the contract shall be interpreted
              against the Party responsible for the drafting or preparation of
              the Agreement, shall not apply.
              <br />
              <br />
              h. This Agreement shall be binding on and enforceable by the
              estates, heirs, executors, administrators, trustees, permitted
              assigns or liquidators of the Parties as fully and effectually as
              if they had signed this Agreement in the first instance and
              reference to any Party shall be deemed to include such Party’s
              estate, heirs, executors, administrators, trustees, permitted
              assigns or liquidators, as the case may be.
              <br />
              <br />
              i. The expiration or termination of this Agreement in as far as
              the different service/product options are concerned shall not
              affect such of the provisions of this Agreement as expressly
              provided that they will operate after any such expiration or
              termination or which of necessity must continue to have effect
              after such expiration or termination, notwithstanding that the
              clauses themselves do not expressly provide for this.
              <br />
            </p>
          </li>
        </ol>

        <div>
          <p className="list-heading">POLICIES</p>
          <p style={{ textAlign: "left" }}>
            <strong>SERVICE DELIVERY POLICY</strong>
            <br />
            <br />
            <strong>SECURITY/PASSWORD</strong>
            <br />
            You shall ensure that your password is unique and kept secret at all
            times, not disclosed to unauthorized persons. You shall not part
            with the password, and We shall not be liable for any losses arising
            out of security breaches as a result of the failure of the customer
            to properly secure the password and devices from unauthorized
            persons.
            <br />
            <br />
            <strong>ACCEPTABLE USE POLICY (AUP)</strong>
            <br />
            The purpose of this terms, Acceptable Use Policy ('AUP") is to
            comply with the relevant laws of the Federal Republic of Nigeria; to
            specify to customers and users of our service/website what
            activities and online behavior are considered an unacceptable use of
            the service/website; to protect the integrity of our application and
            to specify the consequences that may flow from undertaking such
            prohibited activities.
          </p>
        </div>
        <ol>
          <li>
            <p className="list-heading">UNLAWFUL USE</p>
            <p style={{ textAlign: "left" }}>
              Our services may only be used for lawful purposes and activities.
              We prohibit any use of our website/application including the
              transmission, storage and distribution of any material or content
              using our application that violates any law or regulation of the
              Federal Republic of Nigeria. This includes:
            </p>
            <p style={{ textAlign: "left" }}>
              a. Any violation of local and international laws prohibiting
              child pornography; obscenity; discrimination (including racial,
              gender or religious slurs) and hate speech; or speech designed to
              incite violence or hatred, or threats to cause bodily harm.
              <br />
              <br />
              b. Any activity designed to defame, abuse, stalk, harass or
              physically threaten any individual in the Federal Republic of
              Nigeria or beyond its borders; including any attempt to link to,
              post, transmit or otherwise distribute any inappropriate or
              defamatory material.
              <br />
              <br />
              c. Any violation of Intellectual Property laws including materials
              protected by local and international copyright, trademarks and
              trade secrets. furthermore, we will not be held liable if you make
              any unlawful use of any multimedia content accessed through the
              search facility provided by our application, or otherwise
              available through access to our application, whether for
              commercial or non-commercial purposes.
              <br />
              <br />
              d. Any violation of the individual’s right to privacy, including
              any effort to collect personal data of third parties without their
              consent.
              <br />
              <br />
              e. Any fraudulent activity whatsoever, including dubious financial
              practices such as pyramid schemes; the impersonation of another
              subscriber without their consent; attempting to acquire sensitive
              information such as usernames, credit card details, etc. by
              masquerading as a trustworthy entity in an electronic
              communication (phishing); advance-fee fraud scams or any attempt
              to enter into a transaction with us on behalf of another
              subscriber without their consent.
              <br />
              <br />
              f. Any violation of the exchange control laws of the Federal
              Republic of Nigeria.
              <br />
              <br />
              g. Any activity that results in the sale, transmission, or
              distribution of pirated or illegal software.
              <br />
              <br />
              h. Failing to respond to a request by a recipient of unsolicited
              mail to be removed from any mailing or direct marketing list and
              continuing to send unsolicited mail following such a request for
              removal.
              <br />
            </p>
            <p style={{ textAlign: "left" }}>
              Where any user resides outside of the Federal Republic of Nigeria,
              permanently or temporarily, such user will be subject to the laws
              of the country in which s/he is currently resident, and which
              apply. On presentation of a legal order to do so, or under
              obligation through an order for mutual foreign legal assistance,
              we will assist foreign law enforcement agencies (LEA) in the
              investigation and prosecution of a crime committed using our
              resources, including the provisioning of all personal identifiable
              data.
            </p>
          </li>

          <li>
            <p className="list-heading">ACTION FOLLOWING BREACH OF THE AUP</p>
            <p style={{ textAlign: "left" }}>
              ​Upon receipt of a complaint or having become aware of an
              incident, we may take any of the following steps:
            </p>
            <p style={{ textAlign: "left" }}>
              i. In the case of application abuse, inform the user’s application
              administrator of the incident and request the application
              administrator or application owner to address the incident in
              terms of this AUP and the NCC Code of Conduct;
              <br />
              <br />
              ii. In severe cases suspend access of the user’s entire application
              until abuse can be prevented by appropriate means;
              <br />
              <br />
              iii. In the case of individual users, warn the user; suspend the
              user’s account and/or revoke or cancel the user’s application
              access privileges completely;
              <br />
              <br />
              iv. In all cases, charge the offending parties for administrative
              costs as well as for machine and human time lost due to the
              incident;
              <br />
              <br />
              v. Assist other applications or website administrators in
              investigating credible suspicions of any activity listed in this
              AUP;
              <br />
              <br />
              vi. Institute civil or criminal proceedings;
              <br />
              <br />
              vii. Share information concerning the incident with other Internet
              access providers, or publish the information, and/or make
              available the users’ details to law enforcement agencies
              <br />
            </p>
          </li>

          <li>
            <p className="list-heading"> PROHIBITED ACTIVITIES</p>
            <p className="list-heading"> a. THREATS TO APPLICATION SECURITY </p>
            <p style={{ textAlign: "left" }}>
              Any activity which threatens the functioning, security and/or
              integrity of our application is unacceptable.
            </p>

            <p className="list-heading"> b. UNSOLICITED, SPAM AND JUNK MAIL </p>
            <p style={{ textAlign: "left" }}>
              Spam and unsolicited bulk mail are highly problematic practices.
              They affect the use and enjoyment of services by others and often
              compromise application security. We will take swift and firm
              action against any user engaging in any of the following
              unacceptable practices:
            </p>
            <p style={{ textAlign: "left" }}>
              i. Sending unsolicited bulk mail for marketing or any other purposes (political, religious or commercial) to people who have not consented to receiving such mail; 
              <br />
              <br />
              ii. Operating or maintaining mailing lists without the express permission of all recipients listed; 
              <br />
              <br />
              iii. Failing to promptly remove from lists invalid or undeliverable addresses or addresses of unwilling recipients or a recipient who has indicated s/he wishes to be removed from such list. 
              <br />
            </p>

            <p className="list-heading"> c. PROTECTION OF MINORS </p>
            <p style={{ textAlign: "left" }}>
              We prohibit the use of our service to harm or attempt to harm a
              minor, including, but not limited to, hosting, possessing,
              disseminating, distributing or transmitting material that is
              unlawful, including child pornography. 
            </p>
          </li>
        </ol>

        <div className="d-flex justify-content-around">
          <button
            className="btn btn-primary col-4 md-col-8 my-4"
            onClick={handleBackButton}
          >
            Back
          </button>
        </div>
      </div>
    </div>
  );
};

export default TermsofUse;
