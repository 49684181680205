import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";

import {
  FormControl,
  OutlinedInput,
  InputAdornment,
  IconButton,
  FormControlLabel,
  Select,
  MenuItem,
  Radio,
  RadioGroup,
} from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";

import { IoMdPerson } from "react-icons/io";
import { FaStickyNote } from "react-icons/fa";
import { FaPhoneAlt } from "react-icons/fa";
import { IoMail } from "react-icons/io5";
import ClearIcon from "@mui/icons-material/Clear";
import HomeIcon from "@mui/icons-material/Home";

import CustomButton from "../../components/customButton/CustomButton";
import SuccessModal from "../../components/modal/SuccessModal";

import "./ContactUs.css";

const InputField = ({
  label,
  name,
  placeholder,
  icon: Icon,
  multiline,
  rows,
  value,
  onChange,
  onClear,
  error,
}) => {
  const inputRef = useRef(null);

  const handleClear = () => {
    onClear(name);
    inputRef.current?.focus();
  };

  return (
    <div className="input-field-container">
      <span className="modal-input-label">{label}</span>
      <FormControl fullWidth variant="outlined">
        <OutlinedInput
          ref={inputRef}
          size="small"
          placeholder={placeholder}
          name={name}
          value={value}
          onChange={onChange}
          multiline={multiline}
          rows={rows}
          sx={{
            borderRadius: "8px",
            border: "1px solid #D1D5DB",
            marginBottom: "20px",
            padding: "3.5px 20px",
            background: "#F9FAFB",
            marginRight: "0px",
            "& .MuiOutlinedInput-input": {
              cursor: "text",
              paddingTop: "8px",
            },
            "& .MuiInputAdornment-root": {
              alignSelf: "flex-start",
              marginTop: "8px",
              height: "auto",
            },
            "& .MuiInputAdornment-positionEnd": {
              alignSelf: "flex-start",
              marginTop: "8px",
            },
          }}
          startAdornment={
            <InputAdornment position="start">
              <Icon />
            </InputAdornment>
          }
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="clear text"
                onClick={handleClear}
                edge="end"
                sx={{
                  padding: "4px",
                  alignSelf: "flex-start",
                }}
              >
                <ClearIcon sx={{ fontSize: 20 }} />
              </IconButton>
            </InputAdornment>
          }
        />
      </FormControl>
      {error && (
        <div className="text-danger">
          <h6>
            <p>{error}</p>
          </h6>
        </div>
      )}
    </div>
  );
};

const ContactUs = () => {
  const [loading, setLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const navigate = useNavigate();
  const isSmScreen = useMediaQuery("(max-width:768px)");

  const [formData, setFormData] = useState({
    contactName: "",
    email: "",
    phone: "",
    guestOrHost: "",
    message: "",
    subject: "",
    enquiryType: "",
    location: "",
  });

  const [errors, setErrors] = useState({});

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
    setErrors((prev) => ({
      ...prev,
      [name]: "",
    }));
  };

  const handleClickClear = (fieldName) => {
    setFormData((prev) => ({
      ...prev,
      [fieldName]: "",
    }));
  };

  const validateForm = () => {
    const newErrors = {};
    if (!formData.contactName) newErrors.contactName = "Name is required";
    if (!formData.email) newErrors.email = "Email is required";
    if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(formData.email)) {
      newErrors.email = "Invalid email address";
    }
    if (!formData.phone) newErrors.phone = "Phone number is required";
    if (!formData.guestOrHost)
      newErrors.guestOrHost = "Please select guest or host";

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async () => {
    if (!validateForm()) return;

    try {
      setLoading(true);
      const { data } = await axios.post(
        `${process.env.REACT_APP_API}/contactUs`,
        {
          Name: formData.contactName,
          Email: formData.email,
          Phone: formData.phone,
          GuestOrHost: formData.guestOrHost,
          Message: formData.message,
          Subject: formData.subject,
          EnquiryType: formData.enquiryType,
          EmailReceiver: process.env.REACT_APP_ADMIN_EMAIL || "",
          Action: "",
        }
      );

      if (!data.success) {
        toast.error(data.message);
      } else {
        setIsModalOpen(true);
        setTimeout(() => {
          navigate("/");
        }, 2000);
      }
    } catch (err) {
      console.log(err);
      toast.error("Something went wrong. Try again.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="">
      <SuccessModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        title="Request Submitted!"
        modalText="Your request has been processed successfully."
      />

      <div className="form-box">
        <div className="col-lg-12">
          <div className="contact-us-container col-lg-12">
            <div className="content-header col-lg-5">
              <div className="col-lg-11 content-word">
                <h3 className="content-title">Contact Us</h3>
                <div className="content-subtitle">
                  We're here to discuss your needs, answer your questions and
                  offer expert solutions
                </div>
              </div>
              <div className="contact-us-contact-modal">
                <FormControl
                  sx={{ width: "100%" }}
                  variant="outlined"
                  className="form-input"
                >
                  <InputField
                    icon={IoMdPerson}
                    onClear={handleClickClear}
                    placeholder="Your name"
                    label="Full name"
                    name="contactName"
                    value={formData.contactName}
                    onChange={handleChange}
                    error={errors.contactName}
                  />

                  <InputField
                    icon={IoMail}
                    onClear={handleClickClear}
                    placeholder="Input email"
                    label="Email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    error={errors.email}
                  />

                  <InputField
                    icon={FaPhoneAlt}
                    onClear={handleClickClear}
                    placeholder="+234*********"
                    label="Phone Number"
                    name="phone"
                    value={formData.phone}
                    onChange={handleChange}
                    error={errors.phone}
                  />

                  <span className="modal-input-label">
                    Are you a guest or a host?
                  </span>
                  <RadioGroup
                    aria-label="guestOrHost"
                    name="guestOrHost"
                    value={formData.guestOrHost}
                    onChange={handleChange}
                    row
                  >
                    <FormControlLabel
                      value="guest"
                      control={<Radio />}
                      label="Guest"
                    />
                    <FormControlLabel
                      value="host"
                      control={<Radio />}
                      label="Host"
                    />
                  </RadioGroup>
                  {errors.guestOrHost && (
                    <div className="text-danger">
                      <h6>
                        <p>{errors.guestOrHost}</p>
                      </h6>
                    </div>
                  )}

                  <InputField
                    icon={FaStickyNote}
                    onClear={handleClickClear}
                    placeholder="Write your message"
                    label="Quick note"
                    name="message"
                    multiline
                    rows={3}
                    value={formData.message}
                    onChange={handleChange}
                    error={errors.message}
                  />

                  <span className="modal-input-label">Conversation type</span>
                  <Select
                    size="small"
                    placeholder="Conversation type"
                    name="subject"
                    value={formData.subject}
                    onChange={handleChange}
                    displayEmpty
                    sx={{
                      borderRadius: "8px",
                      border: "1px solid #D1D5DB",
                      marginBottom: "20px",
                      padding: "3.5px 20px",
                      background: "#F9FAFB",
                      marginRight: "0px",
                      "&:hover": {
                        background: "#f3f4f6",
                      },
                    }}
                    startAdornment={
                      <InputAdornment position="start">
                        <HomeIcon />
                      </InputAdornment>
                    }
                  >
                    <MenuItem value="" disabled sx={{ textAlign: "left" }}>
                      Conversation type
                    </MenuItem>
                    <MenuItem value="Complaints">Complaints</MenuItem>
                    <MenuItem value="Enquiry">Enquiry</MenuItem>
                  </Select>

                  {formData.subject === "Enquiry" && (
                    <InputField
                      icon={FaStickyNote}
                      onClear={handleClickClear}
                      placeholder="Write your enquiry details"
                      label="Enquiry Details"
                      name="enquiryType"
                      multiline
                      rows={3}
                      value={formData.enquiryType}
                      onChange={handleChange}
                      error={errors.enquiryType}
                    />
                  )}

                  <div className="submit-button">
                    <CustomButton
                      label={loading ? "Please wait..." : "Submit"}
                      disabled={loading}
                      onClick={handleSubmit}
                      style={{
                        height: "40px",
                        padding: "8px 70px",
                      }}
                    />
                  </div>
                </FormControl>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
