import React, { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import "./index.css";

const TermsofUse = () => {
  const navigate = useNavigate();
  const { state } = useLocation();

  useEffect(() => {
    // Scroll to the top of the page when the component mounts
    window.scrollTo(0, 0);
  }, []);

  const handleBackButton = () => {
    navigate("/");
  };

  return (
    <div className="container-fluid my-5 p-3">
      <div className="h1 text-center m-header">
        <div className="h1 text-center p-header">
          TERMS & CONDITIONS FOR PROPERTY SHORT LET RENTALS-GUESTS
        </div>
        <ol>
          <li>
            <p className="list-heading">SCOPE</p>
            <p style={{ textAlign: "left" }}>
              This contract sets out the terms upon which our company
              (Nimblecasa Limited) will provide a serviced
              apartment/Accommodation to you. This document therefore serves as
              a binding contract between yourself and our company and all terms
              hereunder will govern the relationship created herein for the
              duration of your stay and beyond as applicable.  
            </p>
          </li>

          <li>
            <p className="list-heading">OUR PLATFORM</p>
            <p style={{ textAlign: "left" }}>
              We provide an online shortlet platform for hosts to maximise and
              monetise their real assets while enabling ease of transactions for
              guests looking for short-term accommodations anywhere in Nigeria. 
              Apartments range from small and simple to large and luxury
              apartments in choice areas, and you can make your selections based
              on your taste, budget, and experience.  We also provide live
              rating platforms for comments on experiences during guests' stay
              in any relevant apartment. 
            </p>
          </li>

          <li>
            <p className="list-heading">OUR RELATIONSHIP</p>
            <p style={{ textAlign: "left" }}>
              This agreement does not create a Landlord and tenant, agent/or
              servant-principal relationship between yourself and the company.
              Our obligation towards you shall therefore be limited to the use
              of the platform and ensuring the optimal functionality of the
              application/platform. 
            </p>
          </li>

          <li>
            <p className="list-heading">YOUR ELIGIBILITY</p>
            <p style={{ textAlign: "left" }}>
              You must be at least 18 years of age to be eligible for apartment
              booking. Proof of age with a valid ID will be required at
              check-in. Any falsification of age will result in immediate
              eviction and all amounts paid including the damage deposit will be
              forfeited. 
            </p>
          </li>

          <li>
            <p className="list-heading">APARTMENT BOOKING CONFIRMATION</p>
            <ol className="lower-alpha">
              <li>
                <p style={{ textAlign: "left" }}>
                  <strong>Reservation/booking</strong>
                  <br />
                  You may make apartment bookings and reservations via the
                  application/platform by following relevant prompts.
                </p>
              </li>
              <li>
                <p style={{ textAlign: "left" }}>
                  <strong>Rates</strong>
                  <br />
                  All rates are as quoted by the hosts/managers at their
                  discretion. A service charge is included at checkout. 
                </p>
              </li>
              <li>
                <p style={{ textAlign: "left" }}>
                  <strong>Invoice and billing</strong>
                  <br />
                  You will receive the details of your booking which shall
                  include the type and address of the apartment, duration of
                  stay, the total invoiced amount for the duration and any other
                  information necessary and in compliance with company billing
                  policy from time to time. 
                </p>
              </li>
              <li>
                <p style={{ textAlign: "left" }}>
                  <strong>Payment</strong>
                  <br />
                  All payments must be made in advance to confirm reservations.
                  The Company uses a third-party application integrated into its
                  platform to provide a seamless payment experience. Multiple
                  payment options, including Debit Card, USSD, Visa QR, and Bank
                  Transfer, are available for the convenience of customers.
                  Additional payment methods, as indicated and approved by the
                  Company during the reservation process, may also be utilized. 
                  <br />
                  <br />
                  Customers are encouraged to make full payment upon reservation
                  confirmation. However, select accommodations may allow part
                  payment at the time of booking, with the remaining balance due
                  no later than 48 hours before the scheduled check-in date. All
                  payments are subject to processing under the terms and
                  conditions established by the Company and its payment
                  partners.
                </p>
              </li>
              <li>
                <p style={{ textAlign: "left" }}>
                  <strong>Rights and obligations </strong>
                  <br />
                  Your leave and license to occupy any accommodation identified
                  and selected on our platform will be limited to the time
                  selected and agreed for use of the same on the platform. This
                  agreement therefore gives you no other rights either express
                  or implied to the accommodation. You therefore have no
                  statutory rights of notices and are therefore strictly bound
                  by the terms stated herein as to timelines and manner of use
                  of the apartment as stated herein. 
                </p>
              </li>
              <li>
                <p style={{ textAlign: "left" }}>
                  <strong>Refunds </strong>
                  <br />
                  Refund can only be made when the reported issue is confirmed
                  by the host. All refund is made to the same source of payment.
                  Refunds will usually take between 10 to 15 working days
                  barring any banking administrative issues. You will be
                  informed of any such delays accordingly.
                </p>
              </li>
            </ol>
          </li>

          <li>
            <p className="list-heading">
              BOOKING ALTERATION (Cancellation, extension etc)
            </p>
            <p style={{ textAlign: "left" }}>
              Any alteration to your booking such as extension, reduction,
              cancellations etc shall be done via your profile on the platform.
              You may therefore communicate your intentions with the host via
              the platform. Relevant charges shall apply to changes made
              accordingly
            </p>
            <ol className="lower-alpha">
              <li>
                <p style={{ textAlign: "left" }}>
                  <strong>Booking reduction and Cancellation:</strong>
                  <br />
                  This shall be subject to host cancellation policies which is
                  displayed on the property page. All cancellations must be done
                  in writing.
                </p>
              </li>
              <li>
                <p style={{ textAlign: "left" }}>
                  <strong>Booking Extension or reduction</strong>
                  <br />
                  In the event that you require to extend your stay, you will be
                  required to contact us 24 hours before check-out. Extensions
                  are strictly based on the continued availability of the
                  apartment, thus in the event that the apartment has already
                  been let out, extensions may be declined by the company, or an
                  alternative apartment be offered for the extended period if
                  available.  
                  <br />
                  <br />
                  Upon extension approval and confirmation, a fresh invoice will
                  be issued to you for the said period and full payment of the
                  invoice must be made with immediate effect to confirm the
                  extended reservation.
                </p>
              </li>
            </ol>
          </li>

          <li>
            <p className="list-heading">OCCUPANCY AND USE OF APARTMENT</p>
            <ol className="lower-alpha">
              <li>
                <p style={{ textAlign: "left" }}>
                  <strong>Check-in and arrival</strong>
                  <br />
                  Your occupation of our apartment starts at check-in. This is
                  symbolised by the handover of apartment keys to you on the
                  date indicated and approved. Hosts are obligated to grant you
                  access to apartments by issuing the guest with one set of keys
                  or passcode as may be applicable. In the event however that
                  you do not show up (no-show) on the agreed check-in date
                  (without any notification), your check-in will be deemed to
                  have taken place for the day and your payment forfeited
                  accordingly.
                  <br />
                  <br />
                  Guests check-in is subject to host policies which will be
                  stated on the apartment listing. Basic check-in time is
                  however slated for 2 pm on the day of arrival. You may however
                  inform the host in the event that early check-in is required.
                </p>
              </li>
              <li>
                <p style={{ textAlign: "left" }}>
                  <strong>Number of occupants per reservation</strong>
                  <br />
                  We take apartment crowding very seriously in order for our
                  facilities in apartments not to be overstretched, or damaged.
                  It is also your duty to declare the actual number of occupants
                  per reservation in order for our platform to adequately
                  provide proper property listing. The number of guests
                  permitted to occupy each apartment will be specified on our
                  platform. Your reservation is confirmed for only the number of
                  guests listed on your confirmation. Exceeding this occupancy
                  limit or falsifying occupancy is strictly prohibited. And
                  shall give the host the right to act as follows:
                  <ol type="1">
                    <li>charge for additional guests</li>
                    <li>
                      take steps to ensure the vacation of additional guests
                    </li>
                    <li>cancellation of the reservation.</li>
                  </ol>
                </p>
              </li>
              <li>
                <p style={{ textAlign: "left" }}>
                  <strong>Duty Of Care/Responsibility</strong>
                  <br />
                  <ol type="1">
                    <li>
                      <p style={{ textAlign: "left" }}>
                        <strong>Respect for other occupants:</strong>
                        <br />
                        It is the responsibility of the Guest to ensure they are
                        respectful to other occupants in the event that the
                        apartment is one of many in a block. To this extent, any
                        acts of nuisance to other occupants (such as, but not
                        limited to excessive noise, blockade of entrances, acts
                        of violence to staff and other guests, use of drugs,
                        reckless acts, exposure of dirt and filth on the
                        premises, etc.) will be a ground for the immediate
                        termination of your stay with no further liability to
                        the host. 
                      </p>
                    </li>
                    <li>
                      <p style={{ textAlign: "left" }}>
                        <strong>Damage to property:</strong>
                        <br />
                        You have a responsibility and duty of care to ensure
                        damage is not inflicted on the property or its contents.
                        The hosts may therefore carry out a property inspection
                        exercise upon checkout. This is to ascertain the
                        close-out conditions from your occupancy. Any damage
                        caused by you as identified will be charged to you by
                        the host, and you will be liable to make payment
                        accordingly. 
                      </p>
                    </li>
                    <li>
                      <p style={{ textAlign: "left" }}>
                        <strong>Excessive Use of Facilities:</strong>
                        <br />
                        Where it is deemed that the use of facilities such as
                        water, gas and electricity has been abused, an
                        additional charge may be made to you by the host. 
                      </p>
                    </li>
                    <li>
                      <p style={{ textAlign: "left" }}>
                        <strong>Cleanliness:</strong>
                        <br />
                        The apartment is expected to be used in a respectable
                        manner. You are therefore expected to exercise basic
                        hygiene during your stay. Therefore, toilets, bedding,
                        kitchen and all other facilities are to be left in a
                        reasonable state of cleanliness. You will be charged for
                        cleaning if the host views your use of the apartment as
                        reckless in any manner. 
                      </p>
                    </li>
                    <li>
                      <p style={{ textAlign: "left" }}>
                        <strong>Lost Property:</strong>
                        <br />
                        You are solely responsible for your personal items and
                        the company, nor the host shall take any responsibility
                        for lost, damaged, or misplaced personal effects. 
                      </p>
                    </li>
                    <li>
                      <p style={{ textAlign: "left" }}>
                        <strong>Social events:</strong>
                        <br />
                        Except expressly permitted by the host, social events
                        such as but not limited to house parties, religious
                        gatherings (except normal prayers amongst guests),
                        grills, and raves are prohibited.
                        <br />
                        <br />
                        Should this however be the main aim of your reservation,
                        such details should be expressly stated on the platform
                        or communicated with the host for approval. 
                      </p>
                    </li>
                    <li>
                      <p style={{ textAlign: "left" }}>
                        <strong>Lease or assignment of apartments:</strong>
                        <br />
                        Sub-leasing or assignment of apartments is strictly
                        prohibited. The host shall therefore be at liberty to
                        request for identification at the point of checking for
                        verification purposes. In the event that bookings are
                        done on behalf of others, this should be indicated upon
                        reservation. 
                      </p>
                    </li>
                    <li>
                      <p style={{ textAlign: "left" }}>
                        <strong>No Smoking policy:</strong>
                        <br />
                        Unless otherwise specifically indicated by hosts,
                        Smoking is prohibited within apartments. Hosts shall be
                        free to charge or penalise guests for clean up and odour
                        removal upon discovery of any evidence of smoking in the
                        apartment.
                      </p>
                    </li>
                    <li>
                      <p style={{ textAlign: "left" }}>
                        <strong>No Pets:</strong>
                        <br />
                        Unless otherwise specifically indicated, Pets of all
                        kinds are prohibited. Hosts shall be free to charge or
                        penalise guests for clean up and odour removal upon
                        discovery of any evidence of pets in the apartment.
                      </p>
                    </li>
                    <li>
                      <p style={{ textAlign: "left" }}>
                        <strong>Illegal activity:</strong>
                        <br />
                        Illegal activity including the possession of illegal
                        substances is strictly prohibited anywhere in or on the
                        property. Any guest(s) engaging in illegal activity will
                        be subject to immediate eviction and or report to the
                        authorities, and all amounts paid including damage
                        deposit will be forfeited.
                      </p>
                    </li>
                  </ol>
                </p>
              </li>
            </ol>
          </li>

          <li>
            <p className="list-heading">CHECK-OUT AND VACATION</p>
            {/* <ol className="lower-alpha"> */}
            <ol type="1">
              <li>
                <p style={{ textAlign: "left" }}>
                  <strong>Checkout inspection:</strong>
                  <br />
                  The host shall be at liberty to inspect the apartment on the
                  date and time. Any identified damage or loss of property will
                  be charged to the guest if applicable. Photographic evidence
                  will also be taken of any such damage by the Guest, where
                  possible in the event of the need to escalate issues.  You
                  will be required to do a key handover in the manner specified
                  by the company.
                </p>
              </li>
              <li>
                <p style={{ textAlign: "left" }}>
                  <strong>Handover of keys:</strong>
                  <br />
                  The keys of the apartment must be handed over in the manner
                  prescribed by the host. Where checkout inspection is carried
                  out, you will immediately upon completion of inspection hand
                  over the keys to the host. Where no inspection is carried out,
                  you will be instructed on handover mode to wit must be done
                  timeously.
                  <br />
                  <br />
                  Delays in checkout will attract additional billing for a
                  full-day rate as a penalty. Key collection details will be
                  provided prior to the check-in date.
                </p>
              </li>
            </ol>
          </li>

          <li>
            <p className="list-heading">ADDITIONAL SERVICES AND FACILITIES</p>
            <p style={{ textAlign: "left" }}>
              You will be informed by the host, of any additional services and
              facilities provided. This may include Broadband, and in-house
              services such as chefs, cleaners, butlers or other errand
              personnel upon request. The cost of any such additional services
              will be billed along with the apartment cost.
            </p>
          </li>

          <li>
            <p className="list-heading">GENERAL TERMS OF USE</p>
            <p style={{ textAlign: "left" }}>
              By virtue of your acceptance of these terms, you are bound by the
              <Link
                style={{ color: "#ee7b0d", textDecoration: "none" }}
                to="/terms-of-use-general"
              >
                <i> general terms of use</i>
              </Link>{" "}
              of our platform in its entirety.
            </p>
          </li>

          <li>
            <p className="list-heading">LIABILITY</p>
            <p style={{ textAlign: "left" }}>
              The company is not responsible in any way for loss of or damage to
              you or your personal belongings. You are advised to keep your
              belongings safe and to carry out occupational activities with
              care.
              <br />
              <br />
              You acknowledge that you are responsible for the conduct of all
              persons you invite or allow to remain on or in the property during
              the dates of this reservation.
            </p>
          </li>

          <li>
            <p className="list-heading">COMPLAINTS</p>
            <p style={{ textAlign: "left" }}>
              In the event that you are not entirely satisfied with the service
              offered, you may notify us of any complaints as soon as possible
              via the complaints and escalation section of the platform/app. In
              the event of complaints based on apartment or host, the company
              will take steps to foster an amicable settlement between parties.
              Where an amicable settlement cannot be achieved, the company will
              encourage parties to resolve the matters by mediation. 
            </p>
          </li>

          <li>
            <p className="list-heading">
              GOVERNING LAWS AND DISPUTE RESOLUTION
            </p>
            <p style={{ textAlign: "left" }}>
              This Agreement shall be governed by the laws of the Federal
              Republic of Nigeria. Disputes shall be resolved either at the
              Lagos State Multidoor Court House or at any court of competent
              jurisdiction.
            </p>
          </li>

          <li>
            <p className="list-heading">ACKNOWLEDGMENT AND ACCEPTANCE</p>
            <p style={{ textAlign: "left" }}>
              I understand and agree to the terms stated herein and by signing
              this agreement, I agree to all terms and conditions.
            </p>
          </li>
        </ol>

        <div className="d-flex justify-content-around">
          <button
            className="btn btn-primary col-4 md-col-8 my-4"
            onClick={handleBackButton}
          >
            Back
          </button>
        </div>
      </div>
    </div>
  );
};

export default TermsofUse;
