import React, { useEffect } from 'react';
// import { CheckCircle2 } from 'lucide-react';
import './SuccessModal.css';

const SuccessModal = ({ 
  isOpen, 
  onClose, 
  title = "Success!", 
  modalText = "Your request has been submitted successfully."
}) => {
  useEffect(() => {
    if (isOpen) {
      const timer = setTimeout(() => {
        onClose();
      }, 5000);
      return () => clearTimeout(timer);
    }
  }, [isOpen, onClose]);

  if (!isOpen) return null;

  return (
    <div className="alert-overlay">
      <div className="alert-container">
        <div className="alert-content">
          <div className="success-icon">
            {/* <CheckCircle2 
              size={24} 
              className="check-icon"
              color="#4CAF50"
            /> */}
             <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                class="bi bi-check-lg"
                viewBox="0 0 16 16"
              >
                <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425z" />
              </svg>
          </div>
          <div className="text-container">
            <h2 className="alert-title">{title}</h2>
            <p className="alert-text">{modalText}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SuccessModal;