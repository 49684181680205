import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import "./HeroSection.css";

const HeroSection = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const handleLogoClick = () => {
    if (location.pathname === "/") {
      // Reload the page from the top
      window.scrollTo({ top: 0, behavior: "smooth" });
    } else {
      // Navigate to home page
      navigate("/");
    }
  };

  const handleContactClick = (event) => {
    event.preventDefault();
    navigate("/contact-us");
  };

  // const scrollToContact = (event) => {
  //   event.preventDefault();
  //   document.getElementById('contact-section').scrollIntoView({
  //     behavior: 'smooth'
  //   });
  // };

  return (
    <div className="hero-container">
      <nav className="nav-bar">
        <div
          className="logoHero"
          style={{ cursor: "pointer" }}
          onClick={handleLogoClick}
        >
          <img
            src="/NimbleLetLogo.webp"
            alt="Logo"
            style={{ pointerEvents: "none" }}
          />
        </div>
        <button
          onClick={handleContactClick}
          className="contact-button contact-button2"
        >
          Contact Us
        </button>
      </nav>
      <div className="content-section">
        <h1 className="main-title">
          NimbleCasa: Your Gateway to Seamless Shortlet Stay
        </h1>
        <h2 className="sub-title">FIND. BOOK. RELAX.</h2>
        <button className="app-button">Get the App</button>
      </div>
    </div>
  );
};

export default HeroSection;
