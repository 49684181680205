import React, { useEffect } from 'react';
import "./App.css";
import HeroSection from "./components/heroSection/HeroSection.js";
import OfferSection from "./components/offerSection/OfferSection.js";
import FeaturesList from "./components/featuresSection/FeaturesSection.js";
import DownloadButtons from "./components/downloadButtons/DownloadButtons.js";
import Footer from "./components/footer/Footer.js";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import PrivacyPolicy from "./document/PrivacyPolicy.js";
import TermsofUseGuest from "./document/TermsofUseGuest.js";
import TermsofUseHost from "./document/TermsofUseHost.js";
import ContactUs from "./pages/contactUs/ContactUs.js";
import TermsofUseGeneral from "./document/TermsofUseGeneral.js";
import ContactSection from "./components/contactSection/ContactSection.js";

export const HomePage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <HeroSection />
      <OfferSection />
      <FeaturesList />
      <DownloadButtons />
    </>
  );
};

function App() {
  return (
    <BrowserRouter>
      <div className="App">
        <Routes>
          <Route
            path="/"
            element={
              <>
                <HomePage />
                <Footer />
              </>
            }
          />
          <Route
            path="/contact-us"
            element={
              <>
                <ContactUs />
                <Footer />
              </>
            }
          />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/terms-of-use-guest" element={<TermsofUseGuest />} />
          <Route path="/terms-of-use-host" element={<TermsofUseHost />} />
          <Route path="/terms-of-use-general" element={<TermsofUseGeneral />} />
        </Routes>
      </div>
    </BrowserRouter>
  );
}

export default App;
